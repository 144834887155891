import { graphql } from "gatsby";
import React from "react";
import { Ot, OtTemplateQuery } from "../../graphql-types";
import { usePageContext } from "../components/context/PageContext";
import { OtImageHeader } from "../components/card/OtImageHeader";
import { Logos } from "../components/criteres/Logos";
import { OtCarousel } from "../components/ot/OtCarousel";
import { Languages } from "../components/criteres/Languages";
import { StyledLink } from "../components/styled/StyledLink";
import {
  LinkButtonColor,
  StyledButton,
} from "../components/styled/StyledButton";
import * as styles from "../styles/pages/ot.module.scss";
import { Criteres } from "../components/criteres/Criteres";
import { Labels } from "../components/criteres/Labels";
import { SEO } from "../components/seo/seo";
import { BreadCrumb } from "../components/breadcrumb/BreadCrumb";

interface Props {
  data: OtTemplateQuery;
  pageContext: any;
}

const OtTemplate: React.FC<Props> = ({ data, pageContext }) => {
  const { lang, toggleFavorite, favorites } = usePageContext();
  const { ot, sanityFavoritePage, sanityEventsPage } = data;

  return (
    <>
      <SEO
        title={(ot?.libelle?.[lang] || ot?.libelle?.fr) ?? ""}
        image={ot?.medias?.[0]?.resolutions?.[0]?.url ?? ""}
        description={ot?.descriptifs?.[0]?.contenu?.fr ?? ""}
      />
      <OtImageHeader ot={ot as Ot} />
      <BreadCrumb
        label={sanityEventsPage?.data?._rawTitleBreadcrumb?.[lang]}
        labelUrl={"/event"}
        label2={pageContext.selectionName}
        label2Url={pageContext.pathSelection}
        label3={ot?.libelle?.fr ?? ""}
      />
      <div className={styles.wrapper}>
        <div className={styles.logosWrapper}>
          <Labels ot={ot as Ot} />
          <Logos ot={ot as Ot} big />
          <Languages ot={ot as Ot} />
        </div>
        <div className={styles.midContent}>
          <div className={styles.description}>
            {ot?.descriptifs?.map((description, index: number) => (
              <p
                key={`descriptionOt-${index}`}
                itemProp="description"
                dangerouslySetInnerHTML={{
                  __html: description?.contenu?.[lang] ?? "",
                }}
              />
            ))}
          </div>
          <div className={styles.carouselWrapper}>
            <OtCarousel ot={ot as Ot} />
          </div>
        </div>
        <Criteres ot={ot as Ot} />
        <div className={styles.bottomButtonsWrapper}>
          <StyledButton
            color={LinkButtonColor.black}
            onClick={() => toggleFavorite(ot as Ot)}
            label={
              favorites.find(item => item.id === ot?.id)
                ? sanityFavoritePage?.data?._rawRemoveFavoriteLabel?.[lang]
                : sanityFavoritePage?.data?._rawAddFavoriteLabel?.[lang]
            }
          />
          <StyledButton
            color={LinkButtonColor.black}
            url={"/contact"}
            label={"Contactez-nous"}
          />
        </div>
      </div>
    </>
  );
};

export const QUERY = graphql`
  query OtTemplate($id: String) {
    sanityFavoritePage {
      data {
        _rawAddFavoriteLabel
        _rawRemoveFavoriteLabel
      }
    }

    ot(id: { eq: $id }) {
      #       documents {
      #         attributs {
      #           libelle {
      #             fr
      #           }
      #           type
      #         }
      #         id
      #         ordre
      #         url
      #       }
      id
      identification {
        type_objet
      }
      libelle {
        fr
        en
      }
      liens {
        url
        type
      }
      localisation {
        gps {
          lat
          lng
        }
        adresse {
          lignes
          code_postal
          ville
        }
      }
      medias {
        url
        thumbnail
        resolutions {
          type
          url
        }
        attributs {
          libelle {
            fr
          }
          type
        }
      }
      selections {
        id
        libelle {
          fr
        }
      }
      descriptifs {
        contenu {
          en
          fr
        }
      }
      ouvertures {
        date_debut
        date_fin
        heure_debut
        heure_fin
        jours_semaine
        nature
      }
      publication {
        publiable
      }
      criteres {
        id
        libelle {
          fr
        }
        libelle_visible
        valeurs {
          libelle {
            fr
          }
          id
          valorisation {
            position
            valeur
            type
          }
        }
      }
      contacts {
        coordonnees {
          adresse {
            code_postal
            lignes
            ville
            pays {
              code
              libelle {
                de
                en
                fr
                it
              }
            }
          }
          nom
        }
        moyens_communication {
          type
          valeur
        }
        defaut
      }
      #       capacite {
      #         nb_pers_max
      #         nb_pers_min
      #       }
      tarifs {
        nature_prix
        prix_max
        prix_min
        type_duree
        type_prix
        libelle {
          fr
        }
        complement {
          fr
        }
        id
      }
      linkOTS {
        id
        selections {
          id
          libelle {
            fr
          }
        }
        criteres {
          id
          libelle {
            fr
          }
          libelle_visible
          valeurs {
            id
            libelle {
              fr
            }
          }
        }
        descriptifs {
          contenu {
            fr
            en
          }
          html
          libelle
          num
        }
        libelle {
          fr
        }
        liens {
          id
          type
          url
        }
        localisation {
          gps {
            icon
            lat
            lng
          }
          adresse {
            lignes
            code_postal
            ville
          }
        }
        medias {
          attributs {
            libelle {
              fr
            }
          }
          resolutions {
            type
            url
          }
          thumbnail
          url
        }
      }
    }

    sanityEventsPage {
      data {
        _rawTitleBreadcrumb
      }
    }
  }
`;

export default OtTemplate;

import { faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { Ot, CritereValeurs, OtCategoriesQuery } from "../../../graphql-types";
import { useWindowSize } from "../../hooks/useWindowSize";
import { Accordion, AccordionItem } from "../accordion/Accordion";
import { usePageContext } from "../context/PageContext";
import { Capacity } from "./Capacity";
import * as styles from "./criteres.module.scss";
import { InfosPratiques } from "./InfosPratiques";
import { getCapaciteCouvert } from "../filters/SliderFilter";

interface Props {
  ot: Ot;
}

export const Criteres: React.FC<Props> = ({ ot }) => {
  const { sanityOtPage } = useStaticQuery<OtCategoriesQuery>(QUERY);
  const { lang } = usePageContext();
  const [selected, setSelected] = useState(0);
  const { width } = useWindowSize();

  const indexPrestationsEquipements = ot.criteres?.findIndex(
    item => item?.id === "PrestationsEquipements"
  );
  const prestationsEquipements =
    indexPrestationsEquipements !== undefined
      ? (ot.criteres?.[indexPrestationsEquipements]
          ?.valeurs as CritereValeurs[]) ?? []
      : [];
  const indexServicesRestau = ot.criteres?.findIndex(
    item => item?.id === "ServicesRestauration"
  );
  const servicesRestau =
    indexServicesRestau !== undefined
      ? (ot.criteres?.[indexServicesRestau]?.valeurs as CritereValeurs[]) ?? []
      : [];

  // console.log("servicesRestau", servicesRestau);
  const indexAutresServices = ot.criteres?.findIndex(
    item => item?.id === "AutresServicesProposes"
  );
  const autresServices =
    indexAutresServices !== undefined
      ? (ot.criteres?.[indexAutresServices]?.valeurs as CritereValeurs[]) ?? []
      : [];

  const sallesCriteres =
    ot?.criteres?.filter(item => item?.id?.startsWith("Salles")) ?? [];

  const nombreMaxCouvert = getCapaciteCouvert(ot);
  return (
    <div className={styles.wrapper}>
      {width >= 1024 && (
        <>
          <div className={styles.buttonsWrapper}>
            <div
              onClick={() => setSelected(0)}
              className={selected === 0 ? styles.big : ""}
            >
              {sanityOtPage?._rawLabelInfos?.[lang]}
            </div>
            {sallesCriteres?.length > 0 && (
              <div
                onClick={() => setSelected(1)}
                className={selected === 0 ? styles.big : ""}
              >
                {sanityOtPage?._rawLabelCapacity?.[lang]}
              </div>
            )}
            {(prestationsEquipements
              .concat(servicesRestau)
              .concat(autresServices).length > 0 ||
              nombreMaxCouvert > 0) && (
              <div
                onClick={() => setSelected(2)}
                className={selected === 0 ? styles.big : ""}
              >
                {sanityOtPage?._rawLabelServices?.[lang]}
              </div>
            )}
          </div>
          {selected === 0 && (
            <InfosPratiques OT={ot as Ot} />
            // <div className={styles.content}>
            //   <p className={styles.bold}>{"Adresse"}</p>
            //   <p className={styles.bold}>{"Contact"}</p>
            // </div>
          )}
          {selected === 1 && (
            <div className={styles.content}>
              <Capacity ot={ot as Ot} />
            </div>
          )}
          {selected === 2 && (
            <div className={styles.servicesWrapper}>
              {nombreMaxCouvert > 0 && (
                <div className={styles.service}>
                  <p>{"- "}</p>
                  <p>{`${nombreMaxCouvert} couverts`}</p>
                </div>
              )}
              {prestationsEquipements
                .concat(servicesRestau)
                .concat(autresServices)
                .map(item => (
                  <div className={styles.service} key={item.id}>
                    <p>{"- "}</p>
                    <p>{item.libelle?.fr}</p>
                  </div>
                ))}
            </div>
          )}
        </>
      )}
      {width < 1024 && (
        <Accordion>
          <AccordionItem
            title={sanityOtPage?._rawLabelInfos?.[lang]}
            icon={<FontAwesomeIcon icon={faChevronUp} />}
          >
            <InfosPratiques OT={ot} />
          </AccordionItem>
          {sallesCriteres.length > 0 ? (
            <AccordionItem
              title={sanityOtPage?._rawLabelCapacity?.[lang]}
              icon={<FontAwesomeIcon icon={faChevronUp} />}
            >
              <Capacity ot={ot} />
            </AccordionItem>
          ) : null}
          {nombreMaxCouvert ||
          prestationsEquipements.concat(servicesRestau).concat(autresServices)
            .length > 0 ? (
            <AccordionItem
              title={sanityOtPage?._rawLabelServices?.[lang]}
              icon={<FontAwesomeIcon icon={faChevronUp} />}
            >
              <div className={styles.servicesWrapper}>
                {nombreMaxCouvert > 0 && (
                  <div className={styles.service}>
                    <p>{"- "}</p>
                    <p>{`${nombreMaxCouvert} couverts`}</p>
                  </div>
                )}
                {prestationsEquipements
                  .concat(servicesRestau)
                  .concat(autresServices)
                  .map(item => (
                    <div className={styles.service} key={item.id}>
                      <p>{"- "}</p>
                      <p>{item.libelle?.fr}</p>
                    </div>
                  ))}
              </div>
            </AccordionItem>
          ) : null}
        </Accordion>
      )}
    </div>
  );
};

const QUERY = graphql`
  query otCategories {
    sanityOtPage {
      _rawLabelCapacity
      _rawLabelInfos
      _rawLabelServices
    }
  }
`;

import React, { useState } from "react";
import Swiper from "swiper";
import { Carousel } from "../carousel/Carousel";
import { Ot } from "../../../graphql-types";
import { SwiperSlide } from "swiper/react";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./otCarousel.module.scss";
import { usePageContext } from "../context/PageContext";
import { CarouselPagination } from "../carousel/CarouselPagination";
import { ImgIngenie } from "./ImgIngenie";

interface Props {
  ot: Ot;
}

export const OtCarousel: React.FC<Props> = ({ ot }) => {
  const [swiper, setSwiper] = useState<Swiper>();

  // ?.slice(0)
  // .reverse()
  return (
    <>
      <div className={styles.carouselContainer}>
        <Carousel setSwiper={setSwiper}>
          {ot?.medias?.map((_, index: number) => (
            <SwiperSlide key={`${ot?.id}-image-${index}`}>
              <div className={styles.slide}>
                <ImgIngenie
                  ot={ot}
                  className={styles.image}
                  index={index + 1 === ot?.medias?.length ? 0 : index + 1}
                />
              </div>
            </SwiperSlide>
          ))}
        </Carousel>
      </div>
      <CarouselPagination swiper={swiper} spacing={8} />
    </>
  );
};

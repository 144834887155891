import React from "react";
import { Ot } from "../../../graphql-types";
import { useSrcSetFromIngenie } from "../../hooks/useSrcSetFromIngenie";
import { usePageContext } from "../context/PageContext";

interface Props {
  index?: number;
  ot: Ot;
  className?: string;
}

export const ImgIngenie: React.FC<Props> = ({
  ot,
  index = 0,
  className = "",
}) => {
  const { lang } = usePageContext();
  const urls = ot.medias?.[index]?.resolutions as any[];
  const srcSet = useSrcSetFromIngenie(urls);

  return (
    <img
      src={urls[0].url}
      srcSet={srcSet}
      sizes="95vw"
      alt={ot.libelle?.[lang] ?? "tours"}
      className={className}
      loading="eager"
    />
  );
};

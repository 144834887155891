import React from "react";
import { Critere, Ot, OtCriteres } from "../../../graphql-types";
import * as styles from "./capacity.module.scss";

interface Props {
  ot: Ot;
}

const getName = (critere: OtCriteres) =>
  critere?.valeurs?.[0]?.valorisation?.valeur;

const getCategories = (critere: OtCriteres) =>
  critere?.valeurs?.slice(1).map(item => item?.libelle?.fr);

const getValues = (critere: OtCriteres) =>
  critere?.valeurs?.slice(1).map(item => item?.valorisation?.valeur);

export const Capacity: React.FC<Props> = ({ ot }) => {
  const salles: any[] = [];

  const sallesCriteres = ot?.criteres?.filter(item =>
    item?.id?.startsWith("Salles")
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        {getCategories(sallesCriteres?.[0] as OtCriteres)?.map(
          (item, index: number) => (
            <div key={`${index}-cellTitle`}>
              <p className={styles.bold}>{item}</p>
            </div>
          )
        )}
      </div>
      {sallesCriteres?.map((item, index: number) => (
        <div className={styles.row} key={item?.id}>
          <div key={`${index}-cellName`}>
            <p className={styles.bold}>{getName(item as OtCriteres)}</p>
          </div>
          {getValues(item as OtCriteres)?.map((item, i: number) => (
            <div key={`${index}-cellContent-${i}`}>
              <p>{item}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

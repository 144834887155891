import React, { useEffect, useState } from "react";
import * as styles from "./infosPratiques.module.scss";
import { gpsPoint, Map } from "../map/Map";
import { Ot } from "../../../graphql-types";
import { usePageContext } from "../context/PageContext";

interface Props {
  OT: Ot;
}

export const subMenu: any = {
  fr: {
    email: "Contactez par mail",
    address: "Adresse",
    hours: "Horaires",
    contacts: "Contacts",
  },
  en: {
    email: "Contact by mail",
    address: "Address",
    hours: "Hours",
    contacts: "Contacs",
  },
  es: {
    email: "Contactar por correo electrónico",
    address: "dirección",
    hours: "horas",
    contacts: "contactos",
  },
  de: {
    email: "Kontakt per Mail",
    address: "Adresse",
    hours: "Std",
    contacts: "Kontakte",
  },
};

export const makeContact = (
  type: string,
  value: string,
  lang: string,
  OT: any
) => {
  //   const gtagMarkerContact = (label: string) => {
  //     if (typeof window !== undefined) {
  //       window.gtag("event", `clic_${label}`, {
  //         event_category: window.location.pathname,
  //         event_label: `${OT.libelle.fr}-${OT.id.split("|")[1] || ""}`,
  //       });
  //     }
  //   };

  const label =
    type === "TEL" || type === "PORTABLE" || type === "TELPRO"
      ? "Tel.:"
      : "Web :";
  return (
    <div>
      {type === "EMAIL" ? (
        <a
          itemProp="email"
          //   onClick={() => gtagMarkerContact("mail")}
          href={`mailto:${value}`}
        >
          <p>{subMenu[lang].email}</p>
        </a>
      ) : type === "SITEWEB" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <p>{label}</p>
          <a itemProp="url" href={value} target="_blank">
            <p>{` ${value}`}</p>
          </a>
        </div>
      ) : (
        <p
          itemProp="telephone"
          //   onClick={() => {
          //     if (label === "Tel.:") {
          //       gtagMarkerContact("tel");
          //     }
          //   }}
        >
          {label} {value}
        </p>
      )}
    </div>
  );
};

export const makeContactPrint = (type: string, value: string, lang: string) => {
  const label =
    type === "TEL" || type === "PORTABLE" || type === "TELPRO"
      ? "Tel.:"
      : "Web :";
  return (
    <div>
      {type === "EMAIL" ? (
        <div itemProp="email">{value}</div>
      ) : type === "SITEWEB" ? (
        <div>
          {label}
          <a itemProp="url" href={value} target="_blank">
            {value}
          </a>
        </div>
      ) : (
        <div itemProp="telephone">
          {label} {value}
        </div>
      )}
    </div>
  );
};

export const makeAddress = (address: any) => {
  if (address) {
    return (
      <div
        itemScope
        itemProp="address"
        itemType="http://schema.org/PostalAddress"
        className={styles.address}
      >
        <p itemProp="streetAddress">{address.lignes.join(", ") + ","}</p>
        <p itemProp="postalCode">{address.code_postal}</p>
        <p itemProp="addressLocality">{address.ville}</p>
      </div>
    );
  }
};

export const InfosPratiques: React.FC<Props> = ({ OT }) => {
  const { lang } = usePageContext();

  const [activePoint, setActivePoint] = useState<gpsPoint>();

  const address = makeAddress(OT.localisation?.adresse);

  useEffect(() => {
    if (OT?.localisation?.gps?.lat && OT?.localisation?.gps?.lng) {
      setActivePoint({
        lat: OT.localisation.gps.lat,
        lng: OT.localisation.gps.lng,
      });
    }
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.carto} itemProp="" itemScope>
        <Map
          round
          // menu={size.width >= 1024}
          OTS={[OT]}
          setActivePoint={setActivePoint}
          activePoint={activePoint}
          blockCenterUserInit
        />
      </div>
      <div
        itemScope
        itemType="https://schema.org/Person"
        className={styles.details}
      >
        {address && (
          <div>
            <p className={styles.bold}>{subMenu[lang].address}</p>
            <div>{address}</div>
          </div>
        )}
        <div>
          <p className={styles.bold}>{subMenu[lang].contacts}</p>
          {OT.contacts?.map((contact: any) =>
            contact.moyens_communication.map((com: any, index: number) => {
              const Contact = makeContact(com.type, com.valeur, lang, OT);
              return (
                <React.Fragment key={`contactInfoP-${index}`}>
                  <div>{Contact}</div>
                </React.Fragment>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { Critere, Ot } from "../../../graphql-types";
import * as styles from "./labels.module.scss";

interface Props {
  ot: Ot;
}

export const Labels: React.FC<Props> = ({ ot }) => {
  const index = indexLabel(ot?.criteres as Critere[]);
  if (index === -1) return null;
  return (
    <div className={`${styles.wrapper}`}>
      {hasLabel("Labels|QUALITTOURISME", ot?.criteres?.[index] as Critere) && (
        <img src="/labels/qualite_tourisme.gif" alt="tours" />
      )}
      {hasLabel("Labels|LALOIREVLO", ot?.criteres?.[index] as Critere) && (
        <img src="/labels/loire_velo.jpg" alt="tours" />
      )}
      {hasLabel("Labels|LACLEFVERTE", ot?.criteres?.[index] as Critere) && (
        <img src="/labels/clef_verte.jpg" alt="tours" />
      )}
    </div>
  );
};

const indexLabel = (criteres: Critere[]) => {
  const index = criteres?.findIndex(item => item.id === "Labels");
  return index;
};

const hasLabel = (label: string, critere: Critere) =>
  critere.valeurs?.find(item => item?.id === "label");

import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Ot } from "../../../graphql-types";
import { useSrcSetFromIngenie } from "../../hooks/useSrcSetFromIngenie";
import { usePageContext } from "../context/PageContext";
import { ImgIngenie } from "../ot/ImgIngenie";
import { LikeCard } from "./LikeCard";
import * as styles from "./otImageHeader.module.scss";

interface Props {
  ot: Ot;
}

export const OtImageHeader: React.FC<Props> = ({ ot }) => {
  const { lang } = usePageContext();

  return (
    <header className={styles.wrapper}>
      <div>
        <div className={styles.likeCard}>
          <LikeCard big OT={ot} />
        </div>
        <div className={styles.imgWrapper}>
          <ImgIngenie ot={ot} className={styles.image} />
        </div>
        <div className={styles.contentWrapper}>
          <h2>{ot.libelle?.[lang]}</h2>
        </div>
      </div>
    </header>
  );
};
